import * as React from 'react';

import { NotificationTypeV1 } from '@oneaudi/audi-notification-display-service';
import { PopupNotificationList } from './components/popup';
import { NotificationsProps } from './FeatureHubAppDefinition';
import { ContentType } from './types';

function getNotificationsTypes(contentType: ContentType): NotificationTypeV1[] {
  switch (contentType) {
    case 'popup':
      return [
        NotificationTypeV1.POPUP_ERROR,
        NotificationTypeV1.POPUP_SUCCESS,
        NotificationTypeV1.POPUP_GENERIC,
      ];
    case 'sticky':
      return []; // TODO: implement when sticky notifications become relevant
    default:
      return [];
  }
}

export const Notifications: React.FunctionComponent<NotificationsProps> = (
  props,
): React.ReactElement | null => {
  const types = props?.contentType ? getNotificationsTypes(props?.contentType) : [];
  const [notifications, updateNotifications] = React.useState(
    props?.notificationService?.getNotifications(types),
  );

  React.useEffect(() => {
    const unsubscriber = props?.notificationService?.subscribe(() => {
      updateNotifications(props?.notificationService?.getNotifications(types));
    });

    return () => {
      unsubscriber?.unsubscribe();
    };
  }, [props?.notificationService]);

  const { contentType } = props;

  switch (contentType) {
    case 'popup':
      return <PopupNotificationList notifications={notifications || []} />;
    case 'sticky':
      return null; // TODO: implement when sticky notifications become relevant
    default:
      return null;
  }
};

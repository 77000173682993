import { Cms, Component as ComponentUtils } from '@oneaudi/audi-myaudi-utils-feature-app';

import { Logger, EnvConfigService, LocaleService } from './feature-service-types';
import { EnvConfig } from './types';
import { Context, ContextValue } from './context';
import { I18n } from './store/i18n';

const CMS_SCOPE = 'notificationfeatureapp';

export interface ProviderProps {
  localeService: LocaleService;
  envConfigService: EnvConfigService;
  logger: Logger;
}

export const Provider = ComponentUtils.createProvider<EnvConfig, ContextValue, ProviderProps>(
  Context,
  ['cms'],
  async (providerProps: ProviderProps, config: EnvConfig) => {
    const { localeService, logger } = providerProps;

    const cmsRequest: Cms.CmsRequest = {
      cmsBaseUrl: config.cms.baseUrl,
      country: localeService.countryCode,
      language: localeService.language,
      scope: CMS_SCOPE,
    };

    const i18n = new I18n(logger, cmsRequest);

    await Promise.all([i18n.init()]);

    return {
      localeService,
      config,
      i18n,
      logger,
    };
  },
  (scope) => {
    switch (scope) {
      default:
        return scope;
    }
  },
);

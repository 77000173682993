import * as React from 'react';
import styled from 'styled-components';
import { Button, Icon, Layout, LayoutItem, Text } from '@audi/audi-ui-react';
import type { SpacingProps, Theme } from '@audi/audi-ui-react';
import { SPACING_M_M } from '@audi/audi-ui-design-tokens';

import {
  NotificationIconV1,
  NotificationSecondaryButtonV1,
  NotificationTextButtonV1,
} from '@oneaudi/audi-notification-display-service';

export enum PopupNotificationType {
  ERROR = 'error',
  GENERIC = 'generic',
  SUCCESS = 'success',
}

const ICON_SIZE = 24;
const TITLE_MARGIN_TOP = 2;
const SECONDARY_BUTTON_WIDTH = 180;

interface PopupNotificationProps extends SpacingProps {
  type: PopupNotificationType;
  notificationId?: string;
  closeLabel?: string;
  title?: string;
  icon?: NotificationIconV1;
  textButton?: NotificationTextButtonV1;
  secondaryButton?: NotificationSecondaryButtonV1;
  onClose?: React.MouseEventHandler<HTMLElement>;
  children?: React.ReactNode;
}

interface StyledNotificationProps {
  'data-notificationid'?: string;
  role?: string;
  children?: React.ReactNode;
}

const StyledNotification = styled.div<StyledNotificationProps>`
  overflow: hidden;

  background-color: var(${({ theme }: { theme: Theme }) => theme.colors.background.level['3']});
`;

const StyledNotificationContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const StyledIcon = styled.div`
  display: grid;
  place-items: center;

  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`;

const StyledNotificationContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
`;

const StyledTitle = styled.div`
  margin-top: ${TITLE_MARGIN_TOP}px;
`;

const StyledSecondaryButton = styled.div`
  margin-top: ${SPACING_M_M}px;
  width: ${SECONDARY_BUTTON_WIDTH}px;
`;

export const PopupNotification: React.FunctionComponent<PopupNotificationProps> = (
  props,
): React.ReactElement => {
  const {
    type,
    notificationId,
    children,
    title,
    icon,
    textButton,
    secondaryButton,
    onClose,
    closeLabel,
  } = props;
  const { ERROR, GENERIC, SUCCESS } = PopupNotificationType;

  return (
    <StyledNotification data-notificationid={notificationId} role="alert">
      <StyledNotificationContentContainer>
        <Layout spacing={['m']}>
          {type !== GENERIC && (
            <LayoutItem spaceInlineEnd="s">
              <StyledIcon>
                {type === SUCCESS && <Icon name="system-select" size="small" variant="positive" />}
                {type === ERROR && <Icon name="caution" size="small" variant="negative" />}
              </StyledIcon>
            </LayoutItem>
          )}
          {type === GENERIC && icon && (
            <LayoutItem spaceInlineEnd="s">
              <StyledIcon>{icon}</StyledIcon>
            </LayoutItem>
          )}
          <StyledNotificationContent>
            <Layout direction="column">
              {typeof title === 'string' && (
                <LayoutItem spaceStackEnd="s">
                  <StyledTitle>
                    <Text as="p" variant="copy2" weight="bold">
                      {title}
                    </Text>
                  </StyledTitle>
                </LayoutItem>
              )}
              <LayoutItem>{children}</LayoutItem>
              {secondaryButton && <StyledSecondaryButton>{secondaryButton}</StyledSecondaryButton>}
              {textButton && <LayoutItem spaceStackStart="m">{textButton}</LayoutItem>}
            </Layout>
          </StyledNotificationContent>
        </Layout>
        {onClose && (
          <LayoutItem spaceStackStart="xxs" spaceInlineEnd="xxs">
            <Button
              variant="icon-tertiary"
              icon="cancel"
              size="small"
              aria-label={closeLabel || 'close'}
              onClick={onClose}
            />
          </LayoutItem>
        )}
      </StyledNotificationContentContainer>
    </StyledNotification>
  );
};

/*
 * ATTENTION! Put only public interfaces in this file and export them all!
 */

// eslint-disable-next-line import/no-unresolved
import * as React from 'react';
import { Subscription } from '@volkswagen-onehub/feature-hub-utils';

export type NotificationIconV1 = React.ReactElement | null;
export type NotificationSecondaryButtonV1 = React.ReactElement | null;
export type NotificationTextButtonV1 = React.ReactElement | null;

export enum NotificationTypeV1 {
  /** Generic popup notification without any additional content except for the close button */
  POPUP_GENERIC = 'popup-generic',
  /** Popup notification that contains a green success icon next to the provided content */
  POPUP_SUCCESS = 'popup-success',
  /** Popup notification that contains a red error icon next to the provided content */
  POPUP_ERROR = 'popup-error',
  // /** Sticky notification that is displayed above the header */
  // STICKY = 'sticky' // not yet implemented
}

/**
 * Reason the notifcation was closed
 */
export enum NotificationCloseTriggerV1 {
  /** The notfication was closed automatically after the provided timeout */
  AUTO = 'auto',
  /** User clicked x (close button) */
  CLICK = 'click',
  /** Close on navigation, if detectable */
  NAVIGATION = 'navigation',
  /** The notification itself triggered closing (e.g. NBA was clicked) */
  SELF = 'self',
  /** Owner/Consumer that created the notification closed the notification */
  OUTSIDE = 'outside',
}

export enum NotificationCloseTypeV1 {
  /** Close notification when the integrator page is changed */
  NAVIGATION = 'navigation',
  /** Close notification only when user dismisses it */
  CLICK = 'click', // not yet implemented for non SPAs
}

export type NotificationCloseCallbackV1 = (trigger: NotificationCloseTriggerV1) => void;

export interface NotificationOptionsV1 {
  /**
   * Optional title for the notification.
   * If provided, the title will be displayed in the notification.
   */
  readonly title?: string;

  /**
   * Optional icon element to be displayed alongside the notification.
   * The icon can add visual context or emphasis to the notification.
   * Note: this property will be set only for notifications of type NotificationTypeV1.POPUP_GENERIC.
   * For other types (NotificationTypeV1.POPUP_SUCCESS and NotificationTypeV1.POPUP_ERROR),
   * the icons are predefined and fixed based on the type.
   */
  readonly icon?: NotificationIconV1;

  /**
   * Optional secondary button for the notification.
   * It will be displayed alongside the notification content.
   * If provided, the secondary button will be displayed in the notification.
   */
  readonly secondaryButton?: NotificationSecondaryButtonV1;

  /**
   * Optional call to action button for the notification.
   * It will be displayed alongside the notification content.
   * If provided, the call to action button will be displayed in the notification.
   */
  readonly textButton?: NotificationTextButtonV1;

  /**
   * If true, enables dark theme for the notification.
   * Dark theme can be used to display the notification in a dark-themed style.
   *
   * Default: false
   */
  readonly darkThemeEnabled?: boolean;

  /**
   * Whether the notification should be closed when the integrator changes the page or only when the user dismisses it
   *
   * Default: NotificationCloseTypeV1.NAVIGATION
   */
  readonly closeOn?: NotificationCloseTypeV1;

  /**
   * If set, the notification is closed after the given number of seconds.
   * If 0 or less than 0, auto-close is disabled
   * Can be max. 10s. If more than 10s are specified, 10s will be used.
   *
   * Default: 6s for all types, 0 if one or all of the following are present - "Close button", "CTA Text button" and "CTA Secondary button"
   */
  readonly autoCloseAfter?: number;

  /**
   * Called when the notification is closed
   *
   * This will be called after subscribers have been updated
   */
  readonly onClose?: NotificationCloseCallbackV1;

  /**
   * If false, no x (close button) will be displayed in the notification
   * This has no effect on sticky notifications
   *
   * Default: true for all popup notifications
   */
  readonly userCloseable?: boolean;
}

/**
 * Represents a currently open notification
 */
export interface NotificationV1 {
  /**
   * Guaranteed to be unique. Consists of owner id and id given in createNotification
   *
   * It is not guaranteed to be safe to use as an HTML id
   */
  readonly id: string;

  /** Content that should be rendered for this notification */
  readonly content: string;

  /** Type of this notification given in createNotification */
  readonly type: NotificationTypeV1;

  /**
   * Optional notification configuration and behavior options.
   * These options can customize the appearance and behavior of the notification.
   */
  readonly options?: NotificationOptionsV1;

  /**
   * This should be used by the rendering party when the user clicks the x in a popup notification
   *
   * It will trigger a close event with trigger 'click'
   *
   * This is only set if userClosable = true was specified on the options of createNotification
   */
  readonly handleUserClose?: () => void;
}

export interface NotificationDisplayServiceV1 {
  /**
   * Opens a notification of the specified type
   *
   * Only one notification with the same id can be open at the same time, regardless of type, content or options.
   * Trying to open a second notification with the same id while one is still open will result in an error being thrown
   *
   * Please note:
   *     1. React context is not available inside of the notification
   *     2. "rich" notifications (i.e. those with a React.ReactElement as content) cannot be rendered on server side
   *
   * @param type Determines default options and the rendering of the notification
   * @param id Id of the notification, which can be used to close it again
   * @param content Content that will be rendered inside of the notification
   *
   * @param options Options for changing the notifications appearance and behavior. For the list of options, check the interface
   *
   * @throws When a notification with the given id is already open
   */
  createNotification(
    type: NotificationTypeV1,
    id: string,
    content: string,
    options?: NotificationOptionsV1
  ): void;

  /**
   * Closes a notification by the id given in createNotification.
   *
   * Only the consumer that opened a notification with the same id before is able to close it,
   * i.e. one feature app cannot close a notification another feature app opened.
   *
   * Calling this method will trigger a close event with trigger 'outside'
   *
   * @param id The id provided in createNotification
   */
  closeNotification(id: string): void;

  /**
   * Query whether a notification owned by the consumer with the given id is open
   *
   * @param id The id provided in createNotification
   * @returns Whether the notification is open
   */
  isOpen(id: string): boolean;

  /**
   * This should only be called by the rendering party (e.g. the NotificationDisplay feature app)!
   *
   * Returns all open notifications from all consumers.
   *
   * If types are specified, only notifications with the specified type are returned.
   * Note: If an empty array is given for types, no notifications will be returned.
   *
   * @param types List of notification types to get. Specifying an empty array will return no notifications
   * @returns All open notifications of the specified types
   */
  getNotifications(types?: NotificationTypeV1[]): NotificationV1[];

  /**
   * Subscribe to any changes of notifications. An event will be fired any time a notification is opened or closed.
   * The event will be fired before the onClose of the closed notification is called
   *
   * This should only be needed by the rendering party
   *
   * @param listener The listener to be called on update
   * @returns an object with an unsubscribe function
   */
  subscribe(listener: () => void): Subscription;

  /**
   * Clears all notifications that should be closed on click. This method needs to be called by integrators that are
   * single page applications, as there is no other way to detect when the actual page changes.
   *
   * Only one update event is fired when all notifications are closed.
   *
   * Calling this method will trigger a close event for each closed notification with trigger 'navigation'.
   * Note: the order of the close events is not specified
   */
  announceIntegratorNavigation(): void;
}

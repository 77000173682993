import { Subscription } from '@volkswagen-onehub/feature-hub-utils';

import {
  NotificationDisplayServiceV1 as NotificationDisplayService,
  NotificationTypeV1 as NotificationType,
  NotificationOptionsV1 as NotificationOptions,
  NotificationV1 as Notification,
  NotificationCloseTriggerV1 as NoticationCloseTrigger,
} from './types';
import { NotificationStoreV1 as NotificationStore } from './store';

export * from './types';

export class NotificationDisplayServiceImplV1 implements NotificationDisplayService {
  public constructor(private consumerId: string, private store: NotificationStore) {}

  createNotification(
    type: NotificationType,
    id: string,
    content: string,
    options?: NotificationOptions
  ): void {
    this.store.createNotification(this.consumerId, type, id, content, options);
  }

  closeNotification(id: string): void {
    this.store.closeNotification(this.consumerId, id, NoticationCloseTrigger.OUTSIDE);
  }

  isOpen(id: string): boolean {
    return this.store.isOpen(this.consumerId, id);
  }

  getNotifications(types?: NotificationType[]): Notification[] {
    return this.store.getNotifications(types);
  }

  announceIntegratorNavigation(): void {
    this.store.announceIntegratorNavigation();
  }

  subscribe(listener: () => void): Subscription {
    return this.store.subscribe(listener);
  }
}

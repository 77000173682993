/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { ThemeProvider } from '@audi/audi-ui-react';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import type { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import I18nContextComponent from '@oneaudi/i18n-context';
import { i18nScope } from './i18n/messages';

import { EnvConfigService, NotificationDisplayService } from './feature-service-types';
import { ContentType, StaticConfig, StaticFalconConfig } from './types';
import { Provider } from './provider';
import { Notifications } from './notifications';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi:envConfigService': EnvConfigService;
  readonly 'notification-display-service': NotificationDisplayService | undefined;
  readonly 'gfa:service-config-provider': GfaServiceConfigProviderV1;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 's2:logger'?: Logger;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
}

export interface NotificationsProps {
  notificationService?: NotificationDisplayService;
  contentType?: ContentType;
}

export function normalizeConfig(
  config?: StaticConfig | StaticFalconConfig,
): StaticConfig | undefined {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (config.__type === 'aem-headless') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return config.config.fields as StaticConfig;
  }
  return config as StaticConfig;
}

function validateConfig(config: StaticConfig | undefined): config is StaticConfig {
  if (!config) {
    return false;
  }

  if (!config.contentType) {
    return false;
  }

  return !(config.contentType !== 'popup' && config.contentType !== 'sticky');
}

const featureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  FeatureServiceDependencies,
  StaticConfig
> = {
  dependencies: {
    featureServices: {
      'audi:envConfigService': '^1.0.0',
      'notification-display-service': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
    },
    externals: {
      '@audi/audi-ui-react': '*',
      react: '^18.2.0',
      'styled-components': '^5.3.11',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
    },
  },

  create: ({ config: rawConfig, featureServices, featureAppId }) => {
    const config = normalizeConfig(rawConfig);
    if (!validateConfig(config)) {
      throw new Error(
        `Invalid configuration for feature app "${featureAppId}": ${JSON.stringify(config)}`,
      );
    }

    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');

    const envConfigService = featureServices['audi:envConfigService'];
    const notificationService = featureServices['notification-display-service'];
    if (!notificationService) {
      throw new Error(`Invalid Notification Service`);
    }
    const logger = featureServices['s2:logger'] || console;
    const localeService = featureServices['gfa:locale-service'];
    const i18nData: I18NServiceV1 = featureServices['dbad:audi-i18n-service'];

    return {
      render: () => {
        return (
          <ThemeProvider>
            <I18nContextComponent i18nData={i18nData} scopes={[i18nScope]}>
              <Provider
                envConfigService={envConfigService}
                localeService={localeService}
                logger={logger}
              >
                <Notifications
                  contentType={config?.contentType}
                  notificationService={notificationService}
                />
              </Provider>
            </I18nContextComponent>
          </ThemeProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;

/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useRef, useCallback } from 'react';

const DEFAULT_HEADER_HEIGHT = 72;

const checkScrollPosition = (
  header: HTMLElement,
  isFixed: boolean,
  setisFixed: (isFixed: boolean) => void,
  top: number,
) => {
  const headerHeight = header ? header?.offsetHeight : DEFAULT_HEADER_HEIGHT;
  // apply fixed based on fa-one-header height
  if (top <= -headerHeight) {
    !isFixed && setisFixed(true);
  } else {
    isFixed && setisFixed(false);
  }
};

const FixedElement = () => {
  const [isFixed, setisFixed] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>(DEFAULT_HEADER_HEIGHT);
  const popUpRef = useRef(null);
  const toggleFixed = useCallback(
    (top: number, header: HTMLElement) => {
      checkScrollPosition(header, isFixed, setisFixed, top);
    },
    [isFixed],
  );
  useEffect(() => {
    const audiHeader: HTMLElement = document.querySelector('#audi-header') as HTMLElement;
    const handleScroll = () => {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      const { top } = popUpRef.current.getBoundingClientRect();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      toggleFixed(top, audiHeader);
      setHeaderHeight(audiHeader ? audiHeader.offsetHeight : DEFAULT_HEADER_HEIGHT);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [toggleFixed]);
  return { popUpRef, isFixed, headerHeight };
};
export default FixedElement;

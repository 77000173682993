import * as React from 'react';
import { Component as ComponentUtils } from '@oneaudi/audi-myaudi-utils-feature-app';

import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { Logger } from './feature-service-types';
import { I18n } from './store/i18n';
import { EnvConfig } from './types';

export interface ContextValue {
  localeService: GfaLocaleServiceV1;
  config: EnvConfig;
  i18n: I18n;
  logger: Logger;
}

export const Context = React.createContext<ContextValue>({
  localeService: {} as GfaLocaleServiceV1,
  config: {} as EnvConfig,
  i18n: {} as I18n,
  logger: {} as Logger,
});

export function withContext<TProps>(
  Component: React.ComponentType<TProps & ContextValue>,
): React.FunctionComponent<TProps> {
  return ComponentUtils.withContext<TProps, ContextValue>(Component, Context);
}

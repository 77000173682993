/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import styled, { css } from 'styled-components';

import Transition from 'react-transition-group/Transition';
import {
  SPACING_S_S,
  BREAKPOINT_M,
  COLOR_BACKGROUND_CARD_DARK,
  COLOR_BACKGROUND_CARD_LIGHT,
} from '@audi/audi-ui-design-tokens';
import { Theme } from '@audi/audi-ui-react';
import { safariAgent } from '../../utils/useragent';

const POPUP_WIDTH = 300;

interface StrictTransitionDuration {
  enter: number;
  exit: number;
}

type TransitionState = 'entering' | 'entered' | 'exiting' | 'exited' | 'unmounted';

interface StyledTransitionProps {
  duration: StrictTransitionDuration;
  transitionState?: TransitionState;
  isSafari?: boolean;
}

export interface PopupTransitionProps {
  children?: React.ReactNode;
  in?: boolean;
}

const StyledPopupTransition = styled.div<PopupTransitionProps & StyledTransitionProps>`
  position: absolute;
  overflow: hidden;
  right: 0;
  width: ${POPUP_WIDTH}px;
  max-height: 0;
  min-height: 0;

  background-color: var(${({ theme }: { theme: Theme }) => theme.colors.background.level['3']});
  filter: var(${({ theme }: { theme: Theme }) => theme.shadow.background.level['3']});
  box-shadow: ${({ isSafari, theme }) =>
    isSafari
      ? `0px 1px 7px 2px ${
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          theme.name.toLowerCase().indexOf('dark') > -1
            ? `${COLOR_BACKGROUND_CARD_LIGHT}`
            : `${COLOR_BACKGROUND_CARD_DARK}`
        }`
      : 'none'}; // use box-shadow on safari browser instead of filter
  transition: all 400ms cubic-bezier(0.75, 0.02, 0.5, 1);
  transform-origin: 50% 0;

  &:first-child {
    z-index: 1000;
  }

  &:first-child + & {
    transform-origin: 50% 50%;
    transform: scale(0.95) translateY(12px);
  }

  & > div {
    transition: opacity 300ms cubic-bezier(0.75, 0.02, 0.5, 1);
  }

  &:nth-child(n + 2) > div {
    opacity: 0.3;
  }

  &:nth-child(n + 2)::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    display: block;
    height: 12px;
    background-color: var(${({ theme }: { theme: Theme }) => theme.colors.background.level['3']});
  }

  &:nth-child(n + 3) {
    opacity: 0;
    z-index: -1;
    transform-origin: 50% 50%;
    transform: scale(0.98) translateY(8px);
  }

  &:nth-child(n + 4) {
    display: none;
  }

  /* -- Desktop -- */

  @media (min-width: ${BREAKPOINT_M}px) {
    position: relative;
    left: auto;

    width: ${POPUP_WIDTH}px;

    &:first-child {
      z-index: 0;
    }

    &:first-child + & {
      transform: none;
    }

    &:nth-child(n + 2) > div {
      opacity: 1;
    }

    &:nth-child(n + 4) {
      display: block;
    }

    &:nth-child(n + 3) {
      transform: none;
      opacity: 1;
      z-index: unset;
    }
  }

  ${({ transitionState }) => {
    switch (transitionState) {
      case 'entering':
        return css`
          opacity: 1;
          margin: ${SPACING_S_S}px 0;

          @media (min-width: ${BREAKPOINT_M}px) {
            opacity: 0;
            margin: 0;
          }

          & > div {
            opacity: 0 !important;
          }
        `;
      case 'entered':
        return css`
          opacity: 1;
          margin: ${SPACING_S_S}px 0;

          @media (min-width: ${BREAKPOINT_M}px) {
            margin: ${SPACING_S_S}px 0;
          }
        `;
      case 'exiting':
        return css`
          /* Add your exiting styles here */
          max-height: 0 !important;
          min-height: 0 !important;
          opacity: 0.3;
          transform: translateY(-250px) !important;
          box-shadow: none;
          & > div {
            opacity: 0;
          }
          &:first-child + & {
            transform: scale(1);
          }
          &:first-child + & > div {
            opacity: 1;
          }
        `;
      default:
        return ''; // Return an empty string for the default case
    }
  }}
`;

const handleTransitionEnd = (event: TransitionEvent) => {
  if (event.target) {
    const eventElement = event.target as HTMLElement;
    eventElement.removeEventListener('transitionend', handleTransitionEnd);
  }
};

const handleOnEntered = (eventTarget: HTMLElement) => {
  eventTarget.addEventListener('transitionend', handleTransitionEnd);
};

/**
 * We have to know which element is in exit state
 *
 * @param eventTarget
 */
const handleOnExit = (eventTarget: HTMLElement) => {
  eventTarget.setAttribute('data-exit', '');
};

export const PopUpTransition: React.FunctionComponent<PopupTransitionProps> = (
  props,
): React.ReactElement => {
  const duration = { enter: 0, exit: 600 };
  const { children } = props;
  const { in: isIn } = props;

  return (
    /* @ts-ignore */
    <Transition
      timeout={duration}
      in={isIn}
      unmountOnExit
      onExit={handleOnExit}
      onEntered={handleOnEntered}
    >
      {(status: TransitionState) => (
        <StyledPopupTransition transitionState={status} duration={duration} isSafari={safariAgent}>
          {children}
        </StyledPopupTransition>
      )}
    </Transition>
  );
};

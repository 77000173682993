/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import styled, { css } from 'styled-components';

import {
  BREAKPOINT_S,
  BREAKPOINT_M,
  BREAKPOINT_L,
  BREAKPOINT_XL,
  PAGE_MARGIN_M,
  PAGE_MARGIN_L,
  PAGE_MARGIN_XL,
} from '@audi/audi-ui-design-tokens';
import { breakpoints } from '../../utils/breakpoints';
import FixedElement from '../../hooks/use-fixed-element';

const POPUP_MARGIN_XS = 10;
const POPUP_MARGIN_S = 37.5;

export interface PopupLinkProps {
  children: React.ReactNode;
}

const sticky = css`
  position: sticky;
  top: 0;
  height: 0;
`;

const fixed = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
`;

const StyledRef = styled.div<{
  ref: React.RefObject<HTMLDivElement>;
  children?: React.ReactNode;
}>``;

const StyledPopup = styled.div<{
  role?: string;
  ['aria-live']?: string;
  isFixed: boolean;
  headerHeight: number;
  children?: React.ReactNode;
}>`
  ${({ isFixed }) => (isFixed ? fixed : sticky)};
  ${({ isFixed, headerHeight }) =>
    !isFixed && headerHeight > 0
      ? `transform: translateY(${headerHeight}px);`
      : ''}; // position notification below header if at top of page
  display: flex;
  justify-content: flex-end;
  z-index: 998;
  height: 0;
  margin: 0 ${POPUP_MARGIN_XS}px;

  ${breakpoints('margin', 'px', [
    { [BREAKPOINT_S]: `0 ${POPUP_MARGIN_S}` },
    { [BREAKPOINT_M]: `0 ${PAGE_MARGIN_M}` },
    { [BREAKPOINT_L]: `0 ${PAGE_MARGIN_L}` },
    { [BREAKPOINT_XL]: `0 ${PAGE_MARGIN_XL}` },
  ])}
`;

const StyledPopupScrollContent = styled.div`
  max-height: 50vh;
  -webkit-overflow-scrolling: touch;
`;

export const PopupGroup = ({ children }: PopupLinkProps) => {
  const { popUpRef, isFixed, headerHeight } = FixedElement();
  return (
    // StyledRef used as placeholder so that ref remains unchanged by styled update
    <StyledRef ref={popUpRef}>
      <StyledPopup role="region" aria-live="polite" isFixed={isFixed} headerHeight={headerHeight}>
        <StyledPopupScrollContent>{children}</StyledPopupScrollContent>
      </StyledPopup>
    </StyledRef>
  );
};

import { Subscribable } from '@volkswagen-onehub/feature-hub-utils';
import { Logger } from '@feature-hub/logger';

import {
  NotificationTypeV1 as NotificationType,
  NotificationOptionsV1 as NotificationOptions,
  NotificationV1 as Notification,
  NotificationCloseTriggerV1 as NotificationCloseTrigger,
  NotificationCloseCallbackV1 as NotificationCloseCallback,
  NotificationCloseTypeV1 as NotificationCloseType,
} from './types';

export * from './types';

interface InternalNotification {
  notification: Notification;
  onClose: NotificationCloseCallback;
  closeOn: NotificationCloseType;
  autoCloseTimer: ReturnType<typeof setTimeout> | undefined;
}

function createUniqueId(owner: string, id: string): string {
  return `${owner}-${id}`;
}

function checkForValidAutoClose(...objects: unknown[]) {
  return objects.some((obj) => obj !== undefined);
}

/**
 * All Notifications should close automatically.
 * Exceptions are when one or more of the following optional Notifications are set to true:
 * ********************
 * Close button -
 * CTA Text button -
 * CTA Secondary button
 * ********************
 */
function getAutoCloseAfter(
  autoCloseAfter: number | undefined,
  type: NotificationType,
  userCloseable: boolean,
  notAutoClose: boolean
): number {
  if (userCloseable || notAutoClose) return 0; // autoCloseAfter is not set

  if (typeof autoCloseAfter === 'number') {
    return Math.max(Math.min(autoCloseAfter, 10), 0);
  }

  // autoCloseAfter is for all types
  return type === NotificationType.POPUP_GENERIC ||
    NotificationType.POPUP_SUCCESS ||
    NotificationType.POPUP_ERROR
    ? 6
    : 0;
}

export class NotificationStoreV1 extends Subscribable {
  private notifications: InternalNotification[] = [];

  public constructor(private logger: Logger) {
    super();
  }

  createNotification(
    owner: string,
    type: NotificationType,
    id: string,
    content: string,
    options: NotificationOptions = {}
  ): void {
    if (this.isOpen(owner, id)) {
      const message = `Notification with ID "${id}" is already open for consumer "${owner}"`;
      this.logger.warn(message);
      throw new Error(message);
    }

    const optionsWithDefaults: Required<NotificationOptions> = {
      title: options.title || '',
      icon: options.icon || null,
      secondaryButton: options.secondaryButton || null,
      textButton: options.textButton || null,
      darkThemeEnabled:
        typeof options.darkThemeEnabled === 'boolean' ? options.darkThemeEnabled : false,
      closeOn: options.closeOn || NotificationCloseType.NAVIGATION,
      autoCloseAfter: getAutoCloseAfter(
        options.autoCloseAfter,
        type,
        typeof options.userCloseable === 'boolean' ? options.userCloseable : true,
        checkForValidAutoClose(options.secondaryButton, options.textButton)
      ),
      onClose: options.onClose || (() => undefined),
      userCloseable: typeof options.userCloseable === 'boolean' ? options.userCloseable : true,
    };

    const notification: Notification = {
      type,
      content,
      id: createUniqueId(owner, id),
      options,
      handleUserClose: optionsWithDefaults.userCloseable
        ? () => this.closeNotification(owner, id, NotificationCloseTrigger.CLICK)
        : undefined,
    };

    const autoCloseTimer = optionsWithDefaults.autoCloseAfter
      ? setTimeout(
          () => this.closeNotification(owner, id, NotificationCloseTrigger.AUTO),
          optionsWithDefaults.autoCloseAfter * 1000
        )
      : undefined;

    this.notifications.unshift({
      autoCloseTimer,
      notification,
      closeOn: optionsWithDefaults.closeOn,
      onClose: optionsWithDefaults.onClose,
    });

    this.updateInternal();
  }

  closeNotification(owner: string, id: string, triggerEvents: NotificationCloseTrigger): void {
    const fullId = createUniqueId(owner, id);

    const index = this.notifications.findIndex(
      (notification) => notification.notification.id === fullId
    );

    if (index === -1) {
      return;
    }

    const notification = this.notifications[index];

    this.closeKnownNotification(index);

    this.fireEventsAfterClose([notification], triggerEvents);
  }

  isOpen(owner: string, id: string): boolean {
    const fullId = createUniqueId(owner, id);
    return Boolean(
      this.notifications.find((notification) => notification.notification.id === fullId)
    );
  }

  getNotifications(types?: NotificationType[]): Notification[] {
    if (!types) {
      return this.notifications.map((n) => ({ ...n.notification }));
    }

    if (!types.length) {
      return [];
    }

    return this.notifications
      .map((notification) =>
        types.indexOf(notification.notification.type) !== -1
          ? { ...notification.notification }
          : undefined
      )
      .filter((n): n is Notification => Boolean(n));
  }

  announceIntegratorNavigation(): void {
    const closedNotifications: InternalNotification[] = [];

    // Start closing the notifications from the back to make sure that we don't run into any
    // problems with moved up items
    for (let i = this.notifications.length - 1; i >= 0; i -= 1) {
      const notification = this.notifications[i];
      if (notification.closeOn === NotificationCloseType.NAVIGATION) {
        closedNotifications.push(notification);

        this.closeKnownNotification(i);
      }
    }

    this.fireEventsAfterClose(closedNotifications, NotificationCloseTrigger.NAVIGATION);
  }

  private closeKnownNotification(index: number): void {
    const notification = this.notifications[index];

    if (notification.autoCloseTimer) {
      clearTimeout(notification.autoCloseTimer);
    }

    this.notifications.splice(index, 1);
  }

  private fireEventsAfterClose(
    notifications: InternalNotification[],
    trigger: NotificationCloseTrigger
  ): void {
    this.updateInternal();

    notifications.forEach((notification) => {
      if (notification.onClose) {
        notification.onClose(trigger);
      }
    });
  }
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TransitionProps } from 'react-transition-group/Transition';
import TransitionGroup from 'react-transition-group/TransitionGroup';

export interface PopupTransitionGroupProps {
  children: React.ReactElement<TransitionProps>[];
}

export class PopupTransitionGroup extends React.Component<PopupTransitionGroupProps> {
  // eslint-disable-next-line react/sort-comp
  private ref = React.createRef<TransitionGroup>();

  private observer = new MutationObserver(() => this.updateTransitions());

  public constructor(props: PopupTransitionGroupProps) {
    super(props);

    this.updateTransitions = this.updateTransitions.bind(this);
  }

  public componentDidMount(): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    window.addEventListener('resize', this.updateTransitions);

    // eslint-disable-next-line react/no-find-dom-node
    const el = ReactDOM.findDOMNode(this.ref.current);
    if (el) {
      this.observer.observe(el, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
      });
    }

    this.updateTransitions();
  }

  public componentWillUnmount(): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    window.removeEventListener('resize', this.updateTransitions);
    this.observer.disconnect();
  }

  private readonly isDesktopMQ =
    typeof window !== 'undefined' && window?.matchMedia(`(min-width: 450px)`);

  static applyHeightLimitToPopup(elPopup: HTMLDivElement, maxHeight?: number): void {
    if (elPopup) {
      const limit = elPopup.firstElementChild ? elPopup.firstElementChild.clientHeight : 0;
      const maxh = typeof maxHeight !== 'undefined' ? maxHeight : limit;
      const minh = typeof maxHeight !== 'undefined' ? maxHeight : 0;
      elPopup.style.maxHeight = `${maxh}px`; // eslint-disable-line no-param-reassign
      elPopup.style.minHeight = `${minh}px`; // eslint-disable-line no-param-reassign
    }
  }

  private updateTransitions(): void {
    // eslint-disable-next-line react/no-find-dom-node
    const el = ReactDOM.findDOMNode(this.ref.current);

    if (el) {
      if (this.isDesktopMQ && this.isDesktopMQ.matches) {
        const popups = el.childNodes;
        const popupCount = popups.length;
        for (let i = 0; i < popupCount; i += 1) {
          const popup = popups[i] as HTMLDivElement;
          PopupTransitionGroup.applyHeightLimitToPopup(popup);
        }
      } else {
        const popups = el.childNodes;
        const popupCount = popups.length;
        let popupFirst = null;
        for (let i = 0; i < popupCount; i += 1) {
          const popup = popups[i] as HTMLDivElement;
          if (popupFirst || popup.hasAttribute('data-exit')) {
            PopupTransitionGroup.applyHeightLimitToPopup(
              popup,
              popupFirst && popupFirst.firstElementChild
                ? popupFirst.firstElementChild.clientHeight
                : undefined,
            );
          } else {
            popupFirst = popup;
            PopupTransitionGroup.applyHeightLimitToPopup(popup);
          }
        }
      }
    }
  }

  public render(): React.ReactNode {
    const { children } = this.props;
    return (
      // @ts-ignore
      <TransitionGroup component="div" ref={this.ref}>
        {children}
      </TransitionGroup>
    );
  }
}

import * as React from 'react';

import { ThemeProvider, audiLightTheme, audiDarkTheme, Text } from '@audi/audi-ui-react';

import { NotificationTypeV1 } from '@oneaudi/audi-notification-display-service';

import { ContextValue, withContext } from '../../context';
import { Notification } from '../../feature-service-types';

import { PopupGroup } from './popup-group';
import { PopupTransitionGroup } from './transition-group';
import { PopUpTransition } from './transition';
import { PopupNotification, PopupNotificationType } from './notification';

export interface PopupNotificationListProps {
  notifications: Notification[];
}

const notificationTypeMapping: { [type in NotificationTypeV1]: PopupNotificationType } = {
  [NotificationTypeV1.POPUP_ERROR]: PopupNotificationType.ERROR,
  [NotificationTypeV1.POPUP_SUCCESS]: PopupNotificationType.SUCCESS,
  [NotificationTypeV1.POPUP_GENERIC]: PopupNotificationType.GENERIC,
};

export const PopupNotificationList: React.FunctionComponent<PopupNotificationListProps> =
  withContext(({ notifications, i18n }: PopupNotificationListProps & ContextValue) => {
    const darkThemeEnabled =
      notifications?.length > 0 ? notifications[0].options?.darkThemeEnabled : false;

    return (
      <ThemeProvider theme={darkThemeEnabled ? audiDarkTheme : audiLightTheme}>
        <PopupGroup>
          <PopupTransitionGroup>
            {notifications?.map((notification) => (
              <PopUpTransition key={notification.id}>
                <PopupNotification
                  notificationId={notification.id}
                  type={notificationTypeMapping[notification.type]}
                  closeLabel={i18n.closeButton()}
                  {...notification.options}
                  onClose={notification.handleUserClose}
                >
                  {typeof notification.content === 'string' && (
                    <Text as="p" variant="copy2">
                      {notification.content}
                    </Text>
                  )}
                </PopupNotification>
              </PopUpTransition>
            ))}
          </PopupTransitionGroup>
        </PopupGroup>
      </ThemeProvider>
    );
  });
